.services-container {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #E3E3E3; /* Light text for readability on dark backgrounds */
    padding: 20px;
    padding-top: 70px; /* Adjust based on header height */
    background-color: #121212; /* Dark background */
}

.service-item, .box {
    background-color: #1E1E2F; /* Slightly lighter than the container for depth */
    margin: 10px 0;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.2); /* Subtle shadow for depth */
    cursor: pointer;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.service-item:hover, .box:hover {
    transform: translateY(-2px); /* Slight raise effect on hover */
    box-shadow: 0 4px 8px rgba(0,0,0,0.3); /* Increased shadow on hover for a "lifting" effect */
}

.services-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; /* Allows items to wrap on smaller screens */
    justify-content: space-around; /* Evenly distribute space around items */
}

.flowchart {
    display: flex;
    justify-content: center;
    gap: 20px; /* Space between boxes */
    padding: 20px 0; /* Padding around the flowchart for spacing */
    flex-wrap: wrap; /* Allows boxes to wrap on smaller screens */
}

.box {
    flex: 1; /* Each box takes equal space */
    min-width: 250px; /* Minimum width for each box to ensure content visibility */
    max-width: 320px; /* Maximum width to prevent boxes from getting too large */
    text-align: center;
    background-color: #252545; /* Dark background for boxes */
    color: #E3E3E3; /* Light text for readability */
    padding: 20px;
    border-radius: 8px; /* Rounded corners for a modern look */
    box-shadow: 0 4px 8px rgba(0,0,0,0.2); /* Shadow for depth */
}

.box:hover {
    transform: translateY(-5px); /* Slight raise effect on hover */
    box-shadow: 0 8px 16px rgba(0,0,0,0.3); /* Enhanced shadow on hover for a "lifting" effect */
}

@media (max-width: 1024px) {
    .services-list {
        flex-direction: column; /* Stack services vertically on smaller screens */
        align-items: center; /* Center align the service items */
    }

    .flowchart {
        flex-direction: column; /* Stack boxes vertically in the flowchart */
    }

    .box {
        width: 100%; /* Full width for smaller screens to utilize space efficiently */
    }
}

@media (max-width: 768px) {
    .service-item, .box {
        padding: 15px; /* Slightly reduce padding for smaller screens */
    }

    .service-item h3, .box h3 {
        font-size: 18px; /* Adjust font size for smaller screens */
    }
}

.home-background {
    background: linear-gradient(135deg, rgba(25, 20, 50, 0.85), rgba(15, 15, 30, 0.85)), url('/src/static/images/astra.jpeg') no-repeat center center fixed;
    background-size: cover;
    color: #E3E3E3;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start; /* Adjusted for better content alignment */
    align-items: center;
    text-align: center;
    padding-top: 60px; /* Added padding at the top for spacing */
}

.home-intro h2, .home-intro p {
    color: #FFFFFF; /* Crisp white for better contrast */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Soft shadow for text readability */
}

.home-intro h2 {
    font-size: 3rem; /* Larger font size for impact */
    margin-bottom: 1rem;
}

.home-intro p {
    font-size: 1.4rem; /* Slightly larger for readability */
    max-width: 650px; /* Adjusted width for optimal line length */
    margin: auto;
}

/* Updated link/button styles for a more modern look */
a, .btn {
    background-color: #007bff;
    color: white;
    padding: 12px 24px; /* Increased padding for a larger click area */
    border-radius: 8px; /* More pronounced rounding */
    text-decoration: none;
    transition: transform 0.2s ease-out, background-color 0.2s ease;
}

a:hover, .btn:hover {
    background-color: #0056b3;
    transform: scale(1.05); /* Slight scale effect on hover */
}

/* Navigation menu */
.home-nav ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    gap: 30px; /* Increased gap for better spacing */
}

/* Updated for a cleaner look */
.home-nav a {
    text-decoration: none;
    color: #FFFFFF;
    padding: 10px;
    transition: color 0.2s ease-in-out;
}

.home-nav a:hover {
    color: #4CAF50; /* Bright accent color for hover state */
}

/* Services and Products list adjustments for a more modern grid layout */
.services-list, .products-list {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin: 40px 0; /* More whitespace around sections */
}

/* Article/Product styling for a cleaner, more modern appearance */
article {
    flex-basis: calc(33% - 40px); /* Adjust based on the gap */
    background-color: #ffffff;
    color: #333;
    padding: 20px;
    margin: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1); /* Softer shadow */
    transition: box-shadow 0.3s ease-in-out;
}

article:hover {
    box-shadow: 0 8px 16px rgba(0,0,0,0.2); /* More pronounced shadow on hover */
}

@media (max-width: 768px) {
    article {
        flex-basis: 100%; /* Full width on smaller screens */
        margin: 10px 0;
    }
}
/* Services list adjustments for a modern layout */
.services-list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px; /* Adds some space between items */
    padding: 40px 0; /* More vertical space around the services list */
}

/* Updated article styling for non-white backgrounds */
article {
    flex: 1;
    min-width: 250px; /* Minimum width to ensure readability */
    max-width: 320px; /* Maximum width for consistency */
    background-color: #293241; /* Example of a dark, modern background color */
    color: #E0E0E0; /* Light text color for readability */
    padding: 20px;
    margin: 10px;
    border-radius: 8px; /* More pronounced rounded corners */
    box-shadow: 0 4px 8px rgba(0,0,0,0.2); /* Soft shadow for depth */
    transition: transform 0.2s ease, box-shadow 0.2s ease; /* Smooth transitions for interaction */
}

article:hover {
    transform: translateY(-5px); /* Slight lift effect on hover */
    box-shadow: 0 8px 16px rgba(0,0,0,0.3); /* Increased shadow on hover for a "lifting" effect */
}

/* Optional: if you use headings or paragraphs inside the articles, you might want to adjust their styles as well */
article h3 {
    color: #FFB703; /* A bright color for headings to stand out */
}

article p {
    line-height: 1.6; /* Improved line height for readability */
}

.home-nav ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center; /* Center align items horizontally */
    gap: 30px; /* Adjusted space between the list items for a cleaner look */
}

.home-nav li {
    display: inline;
}

.home-nav a {
    color: #E0E0E0; /* Light grey text for readability */
    text-decoration: none; /* Removes underline from links */
    font-size: 1.1rem; /* Slightly larger font size for better visibility */
    padding: 5px 0; /* Reduces padding to minimize the button effect */
    border-bottom: 2px solid transparent; /* Adds an underline effect on hover */
    transition: border-color 0.3s ease-in-out; /* Smooth transition for the underline */
}

.home-nav a:hover, .home-nav a:focus {
    border-bottom: 2px solid #4CAF50; /* Bright accent color for hover state */
    color: #FFFFFF; /* Optional: change text color on hover for contrast */
}

.App {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    Background-color: #1a1a2e; /* dark blue */
}

.footer {
display: flex;
    margin-top: auto;
    justify-content: space-between;
    align-items: flex-start;
    background-color: #2D2D2D; /* dark grey */
    color: #BFBFBF; /* light grey */
    padding: 20px;
    font-size: 14px;
    width: 100%;
   
}
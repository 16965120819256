.about-us-container {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #E3E3E3; /* Light grey text for readability on dark backgrounds */
    padding: 20px;
    background-color: #121212; /* Dark background */
}

.company-logo {
    max-width: 180px;
    height: auto;
    margin-bottom: 20px;
    border-radius: 5px;
}

.about-us-card, .section-card {
    background-color: #1E1E2F; /* Slightly lighter than the container for depth */
    margin: 10px 0;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.2); /* Subtle shadow for depth */
    cursor: pointer;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.about-us-card h2, .section-card h3 {
    color: #FFC107; /* Bright yellow color for headers */
}
.careers {
    min-height: 100vh;
    width: 100%;
    margin: 0;
    padding: 70px 20px 20px;
    color: #E3E3E3;
    background-color: #121212;
    box-sizing: border-box;
}

.job-listings {
    max-width: 800px;
    margin: 20px auto;
}

.job-card {
    background-color: #1E1E2F;
    border-radius: 5px;
    padding: 15px;
    margin-bottom: 15px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.5);
}

.job-title {
    color: #4CAF50;
}

.job-description,
.job-pay,
.job-skills,
.job-dates {
    color: #BFBFBF;
}

.careers a,
.careers a:visited {
    color: #BFBFBF;
    text-decoration: none;
}

.careers a:hover,
.careers a:focus {
    color: #FFFFFF;
    text-decoration: underline;
}

@media (max-width: 768px) {
    .careers {
        padding: 60px 10px;
    }

    .job-card {
        padding: 10px;
    }

    .job-listings {
        padding: 0 5px;
    }

    .careers p {
        word-wrap: break-word; /* Ensures words break to prevent overflow */
    }
}

html, body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
}

.home-nav {
    width: 100%;
    background-color: #1a1a2e; /* dark blue */
    color: #e0e0e0; /* light grey */
    padding: 20px 0;
    position: fixed;
    top: 0;
    left: 0;
}
.home-nav ul {
    display: flex;
    justify-content: space-around;
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.home-nav li {
    display: inline;
}

.home-nav a {
    color: #e0e0e0; /* light grey */
    text-decoration: none;
    font-size: 1.1rem;
    transition: color 0.3s ease-in-out;
    background-color: transparent; /* Removes the default blue background */
}

.home-nav a:hover, .home-nav a:focus {
    color: #4CAF50; /* bright accent color for hover state */
    background-color: transparent; /* Ensures that the background remains transparent on hover */
}
@media (max-width: 600px) {
    .home-nav a {
        font-size: 0.9rem;
    }
}
body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    background-color: #1a1a2e;
    color: #BFBFBF;
}


.header {
    background-color: #2D2D2D;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 50px;
    box-sizing: border-box;
}

.privacy-policy-container {
    padding: 20px;
    font-size: 16px;
    margin-top: 60px; /* Add margin-top equal to the height of the header */
}

.privacy-policy-container h1, .privacy-policy-container h2 {
    color: #BFBFBF;
}

.privacy-policy-container p {
    color: #BFBFBF;
    line-height: 1.6;
}

.privacy-policy-container ul {
    list-style-type: none;
    padding-left: 20px;
}

.privacy-policy-container ul li:before {
    content: "-";
    margin-right: 10px;
}

@media (max-width: 600px) {
    .privacy-policy-container {
        font-size: 14px;
    }
}
html, body {
    margin: 0;
    padding: 0;
}

.footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    background-color: #2D2D2D;
    color: #BFBFBF;
    padding: 20px;
    font-size: 14px;
    width: 100%;
    flex-wrap: wrap;
}

.footer .container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    flex-wrap: wrap;
}

.footer .address, .footer .links {
    display: flex;
    flex-direction: column;
}

.footer p, .footer h4 {
    margin: 0;
}

.footer ul {
    display: flex;
    justify-content: space-between;
    padding: 0;
    list-style: none;
    flex-wrap: wrap;
}

.footer ul li {
    margin-right: 15px;
}

.email-link {
    background-color: transparent;
    border: none;
    color: inherit;
    text-decoration: underline;
}

.footer .links a {
    background-color: transparent;
    border: none;
    color: inherit;
    text-decoration: underline;
}

/* Tablet devices */
@media (max-width: 1024px) {
    .footer .container {
        flex-direction: column;
    }

    .footer .address, .footer .links {
        align-items: center;
        margin-bottom: 20px;
    }

    .footer ul {
        justify-content: center;
    }
}

/* Mobile devices */
@media (max-width: 600px) {
    .footer {
        flex-direction: column;
        padding: 10px;
        font-size: 12px;
    }

    .footer .container {
        flex-direction: row;
        justify-content: space-between;
    }

    .footer .address, .footer .links {
        width: 50%;
        margin-bottom: 20px;
        align-items: flex-start; /* Align address to the left */
    }

    .footer .links {
        align-items: flex-end; /* Align links to the right */
    }

    .footer ul {
        flex-direction: column;
        align-items: flex-end;
    }

    .footer ul li {
        margin-right: 0;
        margin-bottom: 10px;
    }
}
